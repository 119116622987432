import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../../views/shared/layout/layout";
import { SEO } from '../../components/SEO/SEO';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Layout>
        <SEO title=" Política de privacidad " description="Política de privacidad de XXXX " robots="noindex nofollow" />
        <div className="docs">
            {children}
        </div>
    </Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Política de privacidad`}</h1>
    <p>{`La presente web ha sido creada por ...`}</p>
    <h2>{`Titularidad`}</h2>
    <p>{`xxxxxx es un dominio en internet de titularidad de xxxxx`}</p>
    <p><strong parentName="p">{`Propiedad intelectual`}</strong></p>
    <p>{`Todos los contenidos del portal, textos, gráficos, imágenes, su diseño y los derechos de propiedad intelectual q ...`}</p>
    <h2>{`Acceso a los contenidos`}</h2>
    <p>{`El acceso al portal de contenidos de ...`}</p>
    <h2>{`Uso del portal`}</h2>
    <p>{`La responsabilidad del uso del portal ...`}</p>
    <h2>{`Protección de datos personales`}</h2>
    <p>{`XXX  prohíbe expresamente la realiz`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      